import React, { Fragment, useEffect, useMemo, useState } from "react";
import type { LoaderFunction, MetaFunction } from "@remix-run/node";
import { json, redirect } from "@remix-run/node";
import { useLoaderData } from "@remix-run/react";
import { cache } from "~/utils/cache";
import type { PossibleThreadContent, ThreadContent } from "~/utils/thread";
import type { ThreadsLoader } from "~/routes/threads";
import { createFakeThread, useThreads } from "~/routes/threads";
import { postThreadMain } from "~/utils/transactions";
import ThreadsEditor from "~/components/threads/ThreadsEditor";
import { Virtuoso, VirtuosoHandle } from "react-virtuoso";
import type { CachedDiscussion } from "~/utils/leocache";
import { leocache, saveLeoCacheSeen } from "~/utils/leocache";
import { CoinzillaWidget } from "~/components/Coinzilla";
import { ThreadView } from "~/components/threads/ThreadView";
import { FullThread } from "~/components/threads/FullThread";
import { getActiveAccount } from "~/session.server";
import { getCookie } from "~/utils/cookie";
import { getInfraSettings } from "~/utils/infra";
import { route } from "~/utils/route";
import classNames from "classnames";
import { useAppStore } from "~/store";
import { Spinner } from "~/components/format/Spinner";
import { useTraceUpdate } from "~/hooks/useTraceUpdate";
import useRenderTracker from "~/hooks/useRenderTracker";
import { isSSR } from "~/utils/ssr";

export const meta: MetaFunction = () => ({
  title: "InLeo | Microblogging in Blockchain",
  "og:title": "InLeo | Microblogging in Blockchain"
});

export const loader: LoaderFunction = async ({ request }) => {
  const activeAccountName = await getActiveAccount(request);

  console.log("User is index");
  if (activeAccountName) {
    const settings = await getInfraSettings(activeAccountName);

    const path = request.url.split(request.headers.get("host") as string)[1];

    console.log({ url: request.url });
    if (
      settings?.settings &&
      path !== `/threads/${settings.settings.default_feed}`
    ) {
      console.error("USER GOT REDIRECTED");
      return redirect(`/threads/${settings.settings.default_feed}`);
    }
  }

  const threadIndices = activeAccountName
    ? await leocache.authorForYou(activeAccountName)
    : await leocache.latestFeed;

  return json({
    threadContents: await cache.getThreads(threadIndices.slice(0, 10)),
    threadIndices
  });
};

export default function Index() {
  const { threadContents, threadIndices } = useLoaderData() as ThreadsLoader;
  const [activeAccount, setPushStartArray] = useAppStore(store => [
    store.account.activeAccount,
    store.threads.setPushStartArray
  ]);

  const [loadedThreads, loadMore, pushToStart, _, isLoadingThreads] =
    useThreads(threadContents, threadIndices);

  useEffect(() => {
    setPushStartArray(pushToStart);
  }, []);

  const handlePost = async (
    body: string,
    pollOptions?: object,
    dimensions?: any
  ) => {
    const operation = await postThreadMain(
      activeAccount!.name,
      body,
      pollOptions,
      dimensions
    );
    createFakeThread(
      pushToStart as (thread: PossibleThreadContent) => void,
      operation,
      threadContents[0].thread.index + 1,
      activeAccount!
    );
  };

  return (
    <Fragment>
      {activeAccount !== null && (
        <ThreadsEditor mainThreadEditor={true} handlePost={handlePost} />
      )}
      <ThreadList
        loadedThreads={loadedThreads as PossibleThreadContent[]}
        loadMore={loadMore as () => void}
        isLoadingThreads={isLoadingThreads as boolean}
        // isThreadView={false as boolean}
        // threadIndices={threadIndices}
      />
    </Fragment>
  );
}

const THREADS_AT_TIME = 6;

interface ThreadListProps {
  loadedThreads: PossibleThreadContent[];
  loadMore: () => void;
  isLoadingThreads: boolean;
  className?: string;
  feedType?: string;
}

export function ThreadList({
  loadedThreads,
  loadMore,
  isLoadingThreads,
  className,
  feedType
}: ThreadListProps) {
  const [lastSize, setLastSize] = useState<number>(loadedThreads?.length || 0);
  const activeAccount = useAppStore(store => store.account.activeAccount);

  useEffect(() => {
    if (
      !activeAccount ||
      (!activeAccount?.active?.key_auths?.[0]?.[0] &&
        !activeAccount?.posting?.key_auths?.[0]?.[0])
    )
      return;
    if (loadedThreads.length === lastSize) return;

    const permlinksFromThreads = loadedThreads.map(
      (thread: PossibleThreadContent) => {
        return thread?.thread?.permlink;
      }
    );
    // insert here
    void (async function () {
      const { auth, proxy } = getCookie("__session");
      const _signature = window.localStorage.getItem("activeAccount") ?? "";

      if (!_signature) return;

      let timestamp;

      try {
        timestamp = JSON.parse(atob(auth))?.timestamp;
      } catch {
        //console.log("User logged in with ", proxy);
      }

      const saveSeenData = {
        permlinks: permlinksFromThreads,
        authorization: {
          public_key:
            proxy === "hivesigner"
              ? activeAccount?.active?.key_auths[0][0] ||
                activeAccount?.posting?.key_auths[0][0]
              : activeAccount?.posting?.key_auths[0][0],
          account: activeAccount?.name,
          signature: _signature,
          hivesigner: proxy === "hivesigner",
          timestamp: timestamp || 0
        }
      };

      saveLeoCacheSeen(saveSeenData as unknown as any);
    })();
    setLastSize(loadedThreads.length);
  }, [loadedThreads]);

  const ref = React.useRef<VirtuosoHandle>(null);

  const permlink = route.previous?.split("/").reverse().at(0);

  const index = loadedThreads.findIndex(
    item => item.thread.permlink === permlink
  );

  useEffect(() => {
    if (!ref.current || index === -1) return;

    setTimeout(() => {
      ref.current?.scrollToIndex({
        index: index - 6,
        align: "end",
        behavior: "auto"
      });
    }, 50);
  }, [index]);

  const memoizedList = useMemo(() => {
    return (
      <div
        className={classNames("relative overflow-hidden", {
          "pb-52": !className?.includes("pb-0")
        })}
      >
        {loadedThreads.length === 0 && !isLoadingThreads && (
          <div className="scale-125 my-4">
            <Spinner text="" />
          </div>
        )}

        <Virtuoso
          context={{ isLoadingThreads }}
          ref={ref}
          useWindowScroll
          className="flex flex-col"
          computeItemKey={(i, item) => item?.content?.permlink || i}
          data={loadedThreads}
          endReached={loadMore}
          increaseViewportBy={{ top: 2500, bottom: 2500 }}
          itemContent={(i, threadContent) => {
            return (
              <ThreadView
                scrolledTo={permlink === threadContent?.content?.permlink}
                threadContent={threadContent}
                key={threadContent?.content?.permlink}
                feedType={feedType}
                inView={false}
              />
            );
          }}
          components={{ Footer }}
        />
      </div>
    );
  }, [loadedThreads, isLoadingThreads]);

  return memoizedList;
}

function Footer(props: any) {
  return (
    <div className="flex justify-center items-center w-full">
      {props.context?.isLoadingThreads && (
        <div className="pt-8 scale-125 my-4">
          <Spinner text="" />
        </div>
      )}
    </div>
  );
}
